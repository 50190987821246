

































import { defineComponent } from '@vue/composition-api';
import { useSnackbar } from '@/modules/snackbar';

export default defineComponent({
  name: 'KOSnackbar',
  setup() {
    const { active, text, success } = useSnackbar();

    return {
      text,
      success,
      timeout: 2000,
      active,
    };
  },
});
